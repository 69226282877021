import "./SignUp.css"
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { registerUser } from "../../store/user-actions";
import { uiActions } from "../../store/uiaction-slice";
const initialState = {
  name: "",
  email: "",
  password: "",
  rePassword: "",
  phone: "",
  roles: ["Developer"],
};
export default function SignUp() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const [checkpass, setCheckpass] = useState(false)
  const { name, email, password, rePassword, phone } = state;


  const handleChange = ({ target: { value, name } }) => {
    setCheckpass(false)

    setState({
      ...state,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {

    console.log("Registering user", password, rePassword)
    if (password != rePassword) {
      dispatch(uiActions.showNotification(
        {
          status: 'failure',
          message: "PLease re-enter password"
        }

      ))
    }
    else {

      e.preventDefault();
      dispatch(registerUser(state)).then((res) => {
        const tem = res?.data?.code
        if (tem == 1000) {
          setState(initialState);
          history.push("/login");
        }
      });
    }
    setCheckpass(false);

  };
  return (
    <section className="signupSection">

      <section className="signupBox">
        <section className="signupDetails">
          {/* <div className="signupNewAccCreateBox"><span className="signupNewAccCreate">Login</span></div> */}

          <section className="signupDetailsSection">
            <span className="signupDetailsSectionHead">Signup To SpacePe</span>
            <div className="signupInput">
              <span>Name</span>
              <input name="name" placeholder="Enter your name"
                value={name}
                onChange={handleChange}
              />
            </div>
            <div className="signupInput">
              <span>Email</span>
              <input type="email" name="email" placeholder="email" id="email"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div className="signupInput">
              <span>Password</span>
              <input type="password" name="password" placeholder="password"
                value={password}
                onChange={handleChange}
              />
            </div>
            <div className="signupInput">
              <span>Re-Enter</span>
              <input type="password" placeholder="Confirm Your Password" name="rePassword"
                value={rePassword}
                onChange={handleChange} />
            </div>
            <div className="signupInput">
              <span>Phone No</span>
              <input type="tel" name="phone" placeholder="Phone Number"
                value={phone}
                onChange={handleChange}
              />
            </div>
            <button className="signupBtn" onClick={handleSubmit}>Register New</button>
            <div className="AlternativeOption">Aleady Have An Account? 
            <Link class="sign-up" data-testid="login-link" to="/login">Sign In</Link>
            
            </div>
          </section>
        </section>
        <section className="signupImg">
          <img src="https://adcrafter.ai/wp-content/uploads/2023/03/signup.svg" alt="img" />
        </section>
      </section>
    </section>
  )
}