import "./PaymentSuccess.css"
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
export default function PaymentSuccess() {
    return (
        <section className="paymentSuccessSection">
            <section className="paymentSuccessBox">
            <div className="paymentSuccessImg">
                <img src="https://img.freepik.com/premium-vector/boy-is-successful-business_118167-5723.jpg?w=740" alt="Img" />
            </div>
            <div className="paymentSuccessInfo">
                <span className="paymentSuccesHead">Congratulations!</span>
                <span className="paymentSuccesSubhead">Payment Success</span>
                <span className="paymentSuccesId">
                    Order Id : 89HBGFSNFJH789
                    {/*Backend Data  */}
                </span>
            </div>
            <div className="paymentSuccessBtns">
                <button className="paymentSuccessDwndBtn"><span>Download Receipt</span><span className="PaymentSuccessdownloadIcon"><SimCardDownloadIcon/></span></button>
                <button className="paymentSuccessProfileBtn"><span>Go To Profile</span><span className="PaymentSuccessProfileIcon"><AccountCircleOutlinedIcon/></span></button>
            </div>

            </section>
        </section>
    )
}