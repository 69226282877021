import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    allUserData: [],
    isAuth: false,
    user: {},
    message: "",
}
const userslice = createSlice(
    {
        name: "user",
        initialState,
        reducers: {
            getUserData(state, action) {
                state.user = action.payload;
                state.isAuth = true;
            },
            setUserData(state, action) {
                state.user = action.payload;
            },
            getAllUserData(state, action) {
                state.allUserData = action.payload.data;
            },
            logoutUser(state, action){
                state.isAuth = false
            }
        }
    })
export const userActions = userslice.actions;
export default userslice;