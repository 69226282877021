import { useState } from "react"
import "./CreateAdmin.css"
import { useDispatch } from "react-redux";
import { createAdmin } from "../../store/user-actions";

export default function CreateAdmin(){
    const [id,setId] = useState("")
    const dispatch = useDispatch()

    const addAdmin = async (e) => {
        e.preventDefault();
        const data = {
            "email" : id
        };
        dispatch(createAdmin())
        

    }
    return(
        <>
            <section className="createAdminMainDiv">
                <section>
                    <label>
                        Email ID
                    </label>
                        <input type="text" placeholder="Enter A Valid Email ID" value={id} onChange={(e)=>setId(e.target.value)} />
                    <button onClick={addAdmin}>
                        Make Admin
                    </button>
                </section>
            </section>
        </>
    )
}