import Home from "../components/Home/Home";
import NotFound from "../pages/NotFound";
import Registration from "../components/Registration/Registration";
import Login from "../components/Login/Login";
import Plans from "../components/Plan/Plans";
import SignUp from "../components/SignUp/SignUp";
import PaymentSuccess from "../components/PaymentSucces/PaymentSuccess";
import PaymentFailure from "../components/PaymentFailure/PaymentFailure";
import CreatePlan from "../components/CreatePlan/CreatePlan";
// import Tnc from "../components/Terms and conditions/TermsAndConditions";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../components/Terms and conditions/TermsAndConditions";
import CancellationsAndRefunds from "../components/CancellationsAndRefunds/CancellationsAndRefunds";
import AboutUs from "../components/AboutUs/AboutUs";
import ChangePassword from "../components/ChangePassword/ChangePassword";
import VerifyUser from "../components/VerifyUser/VerifyUser";
import Profile from "../components/Profile/profile";
import AdminDashboard from "../components/AdminDashboard/AdminDashboard";
import Support from "../components/Support/Support";
import UserOrders from "../components/UserOrders/UserOrders";
import Shipping from "../components/Shipping/Shipping";
import PrintingDocument from "../components/PrintingDocument/PrintingDocument";
import ContactUs from "../components/ContactUs/ContactUs";
const routes = [
    {
      path: "/",
      component: Home,
      title: "Home",
      permittedUser: [],
    },
    {
      path: "/check",
      component: Registration,
      title: "Registration Page",
      permittedUser: [],
    },
    {
      path: "/login",
      component: Login,
      title: "Registration Page",
      permittedUser: [],
    },
    {
      path: "/signup",
      component: SignUp,
      title: "Registration Page",
      permittedUser: [],
    },
    {
      path: "/paymentSuccess",
      component: PaymentSuccess,
      title: "Registration Page",
      permittedUser: [],
    },
    {
      path: "/paymentFailure",
      component: PaymentFailure,
      title: "Registration Page",
      permittedUser: [],
    },
    {
      path: "/plans",
      component: Plans,
      title: "Subscription Plan Page",
      permittedUser: [],
    },
    {
      path: "/shipping",
      component: Shipping,
      title: "Shipping",
      permittedUser: [],
    },
    {
      path: "/contactUs",
      component: ContactUs,
      title: "Contact Us",
      permittedUser: [],
    },
    // aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
    {
      path: "/printingDocument",
      component: PrintingDocument,
      title: "Printing Doc",
      permittedUser: [],
    },
    {
      path: "/createplan",
      component: CreatePlan,
      title: "create Plan Page",
      // permittedUser: ['admin'],
      permittedUser: [],
    },
    {
      path: "/TermsAndConditions",
      component: TermsAndConditions,
      title: "Terms and Conditions",
      permittedUser: [],
    },
    {
      path: "/PrivacyPolicy",
      component: PrivacyPolicy,
      title: "Privacy Policy",
      permittedUser: [],
    },
    {
      path: "/CancellationsAndRefunds",
      component: CancellationsAndRefunds,
      title: "Cencellations And Refunds",
      permittedUser: [],
    },
    {
      path: "/AboutUs",
      component: AboutUs,
      title: "About Us",
      permittedUser: [],
    },
    {
      path: "/change",
      component: ChangePassword,
      title: "About Us",
      permittedUser: [],
    },
    {
      path: "/verify/:userId",
      component: VerifyUser,
      title: "Verifying user Us",
      permittedUser: [],
    },
    {
      path: "/Profile",
      component: Profile,
      title: "About ",
      permittedUser: [],
    },
    {
      path: "/support",
      component: Support,
      title: "Support",
      permittedUser: [],
    },
    {
      path: "/admin",
      component: AdminDashboard,
      title: "Support",
      permittedUser: [''],
    },
    {
      path: "/orders",
      component:UserOrders ,
      title: "Support",
      permittedUser: [],
    },
    {
        path: "*",
        component: NotFound,
        title: "Not Found",
        permittedUser: [],
      },
];
    
export default routes;
    