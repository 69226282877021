import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";
import Popup from 'reactjs-popup';
import { Button } from "@mui/material";
import Card from '@mui/material/Card';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { isAuthentication } from "../../store/user-actions";

import "./UserManagement.css"
import { userLogout } from '../../store/user-actions';
const UserManagement = () => {
    const dispatch =  useDispatch()
    const user= useSelector(state=>state.user.user)
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    const history = useHistory();
    
    const handleLogout = () =>{
        console.log("handel logout")
        dispatch(userLogout())
        window.location.reload()
    }
    const handleLogIn = () =>{
        history.push("/login")
        closeModal()

    }
    const handleChange = () =>{
        history.push("/orders")
        closeModal()
    }

    return (
        <div>
            <div data-testid="card-div-um-0" className="text-end">

                <Popup trigger=
                    {<AccountCircleOutlinedIcon sx={{ color: "#000",cursor: 'pointer' }} />}
                    closeOnDocumentClick
                     onClose={closeModal}
                    >

                    {user && Object.keys(user).length>0 ?
                    (<Card  className="user-popupcard" style={{ right: "100px"}}>
                        <div variant="outlined" className="card-body px-4 py-4">
                            <div  id="user-circle-avatar" className="text-center mx-auto mb-4">
                                <span className='user-span'>{user.name[0].toUpperCase()}</span>
                            </div>
                            <h5 className="text-center mb-0">{user.name} {user.lastName}</h5>
                            <p className="text-center mb-2">{user.email}</p>
                            <hr />
                            <p
                                className="mb-0"
                                style={{ color: "#bebebe", fontWeight: "bold", fontSize: 12 }}
                            >
                                ROLE
                            </p>
                            <p style={{ fontSize: 12 }}>
                               {user.role}
                            </p>
                            <hr className="mb-0" />
                            <div
                                className="list-group list-group-flush"
                                style={{ margin: "0 -24px 0" }}
                            >
                                {user.role==='user' && <Button variant="outlined" onClick={handleChange} style={{ marginLeft: "10px", marginBottom: "2px", widht: "100%" }}>
                                    <small>Your Plans</small>
                                </Button>}
                                <br/>
                                {user.role==='admin' && <Button variant="outlined" onClick={() => { history.push("/admin"); }} style={{ marginLeft: "10px", marginBottom: "2px", widht: "100%" }}>
                                    <small>Admin Dashboard</small>
                                </Button>}
                                <Button variant="outlined" onClick={() => { history.push("/change"); }} style={{ marginLeft: "10px", marginBottom: "2px", widht: "100%" }}>
                                    <small>Change Password</small>
                                </Button>
                                <br />
                                <Button variant="outlined" style={{ marginLeft: "10px", widht: "100%", color: "red" }}>
                                    <small onClick={handleLogout}>Logout</small>
                                </Button>

                            </div>

                        </div>
                    </Card>):
                    (<Card  className="user-popupcard-login" style={{ right: "100px" }}>
                    <div variant="outlined" className="card-body px-4 py-4">
                        
                    <Button variant="outlined" style={{  widht: "100%",alignItems:'center' ,color: "red" ,marginLeft:'20%' }}>
                                <small onClick={handleLogIn}>LogIn</small>
                            </Button>

                    </div>
                </Card>)}
                </Popup>
            </div>
        </div>
    )
}
export default UserManagement;