
import { planAction } from "./plans-slice";

import axiosInstance from "../helper/AxiosInstance";
import { uiActions } from "./uiaction-slice";

export const getAllPlanData = (pageNo = 1, limit = 10) => {
    return async (dispatch) => {
        try {
            const data = await axiosInstance.get('plan/all');
            await dispatch(planAction.getAllPlans(data.data));
        } catch (error) {
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } 
    };
};
export const getActivePlan = (pageNo = 1, limit = 10) => {
    return async (dispatch) => {
        try {
            const data = await axiosInstance.post('plan/all' , {plan_active : true } );
            await dispatch(planAction.getAllPlans(data.data));
        } catch (error) {
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } 
    };
};

export const getUserOrder = (pageNo = 1, limit = 10) => {
    return async (dispatch) => {
        try {

            const data = await axiosInstance.get('orders');
            await dispatch(planAction.getAllPlans(data.data));
        } catch (error) {
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } 
    };
};


export const changePlanStatus = (id) => {
    return async (dispatch) => {
        try {

            const data = await axiosInstance.post("plan/status/",{"plan_id":id});
            if(data){
                uiActions.showNotification({
                    status:"success",
                    message:'Status changed successfully'
                })
            }
        } catch (error) {
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } 
    };
};
