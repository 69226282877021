import React from 'react';
import "./TermsAndConditions.css"
const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <h2>Terms and Conditions</h2>
      <p>Welcome to SpacePe India Services LLP ("SpacePe," "we," "us," or "our"). Our services, which include storage services, Banking as a Service (BaaS), Enterprise Resource Planning (ERP), and software customization services ("Services"), are provided to you ("Client," "you," or "your") subject to the terms and conditions set forth in this document ("Terms").</p>

      <p>By accessing or using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use our Services.</p>

      <h3>1. Services Description</h3>
      <p>SpacePe offers a range of digital transformation services designed to enhance your business operations, including but not limited to storage solutions, BaaS, ERP, and bespoke software development. Each Service's specific terms, including scope, deliverables, and fees, will be detailed in a separate service agreement or order form.</p>

      <h3>2. Use of Services</h3>
      <p><strong>a. Access:</strong> You are granted a non-exclusive, non-transferable, limited right to access and use the Services for your internal business purposes, subject to these Terms.</p>
      <p><strong>b. Restrictions:</strong> You shall not (i) license, sublicense, sell, resell, transfer, assign, distribute, or otherwise commercially exploit or make available to any third party the Services in any way; (ii) modify or make derivative works based upon the Services; (iii) create Internet "links" to the Services or "frame" or "mirror" any content on any other server or wireless or Internet-based device; or (iv) reverse engineer or access the Services to build a competitive product or service.</p>

      <h3>3. Client Responsibilities</h3>
      <p>You are responsible for all activities that occur in your account and for compliance with this agreement, including the content of the data you store through the Services and ensuring that your use of the Services does not violate any applicable laws or regulations.</p>

      <h3>4. Payment of Fees</h3>
      <p>You agree to pay all fees associated with your use of the Services as specified in the service agreement or order form. Unless otherwise stated, all fees are quoted in Indian Rupees and are due within 30 days of the invoice date.</p>

      <h3>5. Confidentiality</h3>
      <p>Both parties agree to maintain the confidentiality of Confidential Information and to not disclose such information to any third party without the prior written consent of the other party, except as required by law.</p>

      <h3>6. Intellectual Property Rights</h3>
      <p><strong>a. Ownership:</strong> SpacePe retains all rights, title, and interest in and to the Services, including all related intellectual property rights. No rights are granted to you hereunder other than as expressly set forth herein.</p>
      <p><strong>b. Client Data:</strong> You retain all rights to your data that you provide in the course of using the Services. You grant SpacePe a worldwide, royalty-free license to use, reproduce, electronically distribute, and display your data solely to the extent necessary to provide the Services.</p>

      <h3>7. Warranties and Disclaimers</h3>
      <p>SpacePe makes no warranties regarding the reliability, availability, timeliness, suitability, accuracy, or completeness of the Services or the results you may obtain by using the Services. The Services are provided "as is" and "as available" without any warranty of any kind.</p>

      <h3>8. Limitation of Liability</h3>
      <p>In no event shall SpacePe's aggregate liability exceed the amounts actually paid by and/or due from you in the twelve (12) month period immediately preceding the event giving rise to such claim. In no event shall SpacePe be liable for any indirect, punitive, special, exemplary, incidental, consequential, or other damages of any type or kind.</p>

      <h3>9. Termination</h3>
      <p>Either party may terminate the Services with or without cause, upon written notice. Upon termination, your right to use the Services stops immediately, and your license to use the software related to the Services ends.</p>

      <h3>10. Governing Law</h3>
      <p>These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions.</p>

      <h3>11. Changes to Terms</h3>
      <p>SpacePe reserves the right, in its sole discretion, to change the Terms under which the Services are offered. The most current version of the Terms will supersede all previous versions.</p>

      <h3>12. Contact Information</h3>
      <p>If you have any questions about these Terms, please contact us at <a href="mailto:mail@spacepe.in">mail@spacepe.in</a>.</p>
    </div>
  );
}

export default TermsAndConditions;
