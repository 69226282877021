import axiosInstance from "../../helper/AxiosInstance";
import { useState } from "react"
import './CreatePlan.css';
import CloseIcon from '@mui/icons-material/Close';




export default function CreatePlan() {
  const [head, setHead] = useState("");
  const [subHead, setSubHead] = useState("");
  const [desc, setDesc] = useState("");
  const [itemName, setItemName] = useState("");
  const [amount, setAmount] = useState(0);
  const [featureName, setFeatureName] = useState("");
  const [featureType, setFeatureType] = useState("");
  const [features, setFeatures] = useState({})
  const [period,setPeriod] = useState("")

  const add = (e) => {
    e.preventDefault()
    setFeatures((prev) => ({ [featureType]: featureName, ...prev }))
  }

  const removeKey = (key) => {
    const updatedFeatures = { ...features };
    delete updatedFeatures[key];
    setFeatures(updatedFeatures);
  };
  
  const handleCreatePlan = async (e) => {
    e.preventDefault();
    const data={
      "item_name": itemName,
      "amount": amount,
      "description": desc,
      "notes": features,
      "heading": head,
      "subHeading": subHead,
      "period" : period
    }
    await axiosInstance
      .post("/plan", data)
      .then((response) => {
        console.log(response);e.target.reset()
      })
      .catch((error) => {
        console.log(error)
      })
      
  }

  console.log(period)

  return (
    <section className="createPlanSection">
      <aside className="createPlanSectionMainContent">
        <h1 className="createPlanHeading">Create New Plan</h1>


        {/* Main Form Div Starting Here */}
        <div className="createPlanMainForm">
          <div className="createPlanInputBoxs">
            <span className="createPlanInputArea">
              <label>Heading :</label>
              <input type="text" value={head} onChange={(e) => setHead(e.target.value)}></input>
            </span>



            <span className="createPlanInputArea">
              <label>Sub-Heading :</label>
              <input type="text" value={subHead} onChange={(e) => setSubHead(e.target.value)}></input>
            </span>



            <span className="createPlanInputArea">
              <label>Description :</label>
              <textarea className="createPlanItemDecription" value={desc} onChange={(e) => setDesc(e.target.value)} rows={"10"} cols={"100"}></textarea>
            </span>


            <span className="createPlanInputArea">
              <label>Item Name :</label>
              <input type="text" value={itemName} onChange={(e) => setItemName(e.target.value)}></input>
            </span>




            <span className="createPlanInputArea">
              <label> Amount :</label>
              <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)}></input>
            </span>


            <span className="createPlanInputArea">
              <label> Period : </label>
              <select className="createPlanInputAreaSelect">
                <option value={period} onClick={()=>setPeriod("monthly")}>Monthly</option>
                <option value={period} onClick={()=>setPeriod("yearly")}>Annually</option>
              </select>
            </span>
          </div>




          <div className="createPlanInputBoxs">
            <form onSubmit={add}>

              <span className="createPlanInputArea">
                <label>Features Type :</label>
                <input
                  type="text" value={featureType} onChange={(e) => setFeatureType(e.target.value)} />
              </span>


              <span className="createPlanInputArea">
                <label>Features Name :</label>
                <input type="text" value={featureName} onChange={(e) => setFeatureName(e.target.value)} />
                <button type="submit" className="createPlanAddButton">Add Feature</button>
              </span>

            </form>
          </div>



          <div className="createPlanInputBoxs">
            <h3>Features</h3>
            <ul className="createPlanList">

              {Object.entries(features).map(([id, value]) => (
                <li className="createPlanListItem" key={id}>
                  <span className="createPlanItemType">{id}</span>  <span className="createPlanItemName">{value}</span>
                  <button onClick={()=>removeKey(id)}><CloseIcon/></button>
                </li>

              ))}
            </ul>
          </div>


          <button onClick={handleCreatePlan} className="createPlanFinalButton">Create Plan</button>

          {/* Main Form Div Ending Here! */}
        </div>
      </aside>
    </section>
  )
}