import React from 'react'
import { useState } from "react";
import { useDispatch } from "react-redux";

import { useParams, useLocation } from 'react-router-dom';
import { forgetPassword } from "../../store/user-actions";
import { Link, useHistory } from "react-router-dom";
import { uiActions } from "../../store/uiaction-slice";

const ForgetPassword = ({ match }) => {
  console.log("rendering forget password")
  const { userId } = match.params;
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const key = queryParams.get('key');

  const history = useHistory()
  const dispatch = useDispatch();
  const [rePassword, setRePassword] = useState("");
  const [password, setPassword] = useState("");

  const handleForget = () => {
    if ((password != rePassword)) {
      dispatch(uiActions.showNotification(
        {
          status: 'failure',
          message: "Password does not match"
        }
      ))
    }
    else if (password == '') {
      dispatch(uiActions.showNotification(
        {
          status: 'failure',
          message: "Please Enter password"
        }))
    }
    else {
      const data = {
        password: password,
        cnf_password: rePassword,
        token: key,
        user_id: userId
      }
      dispatch(forgetPassword(data));
      setPassword("")
      setRePassword("")
    }
  };

  return (
    <section className="forgotSection">
      <section className="loginBox">
        <section className="loginDetails">

          <section className="loginDetailsSection">
            <span className="loginDetailsSectionHead">Forgot Password</span>

            <div className="LoginInput">
              <span>New Password</span>
              <input type="password" name="password" placeholder="Please Enter Your Password" value={password} id="login-password"
                onChange={(e) => setPassword(e.target.value)}></input>
            </div>
            <div className="LoginInput">
              <span>Re-Enter</span>
              <input type="rePassword" name="rePassword" placeholder="Please Enter Again" value={rePassword} id="login-password"
                onChange={(e) => setRePassword(e.target.value)}></input>
            </div>
            <button className="LoginBtn" onClick={handleForget}>Set Password</button>

          </section>

        </section>
        <section className="loginImg">
          <img src="https://www.indiigo.in/assets/images/login.png" alt="img" />
        </section>
      </section>
    </section>


  )
}

export default ForgetPassword