import React from 'react';
import "./PrivacyPolicy.css"

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      <h2>Privacy Policy</h2>
      <p><strong>Introduction</strong></p>
      <p>SpacePe India Services LLP ("SpacePe", "we", "us", or "our") is committed to protecting the privacy and security of your personal and business information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you engage with our digital services, including storage services, Banking as a Service (BaaS), Enterprise Resource Planning (ERP), and software customization services through our website, software applications, or any other digital platform we operate (collectively, the "Services").</p>
      <p>By accessing or using our Services, you signify your understanding of and agreement with this Privacy Policy. If you do not agree with our policies and practices, you should not use our Services.</p>
        <br/>
      <p><strong>Information We Collect</strong></p>
      <p><strong>Personal Information</strong></p>
      <p>Personal information refers to any information that identifies or can be used to identify an individual directly or indirectly. This may include, but is not limited to:</p>
      <ul>
        <li>Contact details (such as name, email address, telephone number)</li>
        <li>Professional details (such as job title, department, or job role)</li>
        <li>Account login credentials (such as usernames and passwords, password hints, and similar security information)</li>
      </ul>
      <br/>
      <p><strong>Business Information</strong></p>
      <p>Business information refers to information related to your business activities and may include:</p>
      <ul>
        <li>Business name</li>
        <li>Business size</li>
        <li>Business type</li>
        <li>Industry sector</li>
      </ul>
      <br/>
      <p><strong>Usage Information</strong></p>
      <p>We collect information about how you use our Services, including but not limited to:</p>
      <ul>
        <li>IP addresses</li>
        <li>Browser types</li>
        <li>Log files</li>
        <li>Access times</li>
        <li>Pages viewed</li>
        <li>Links clicked</li>
      </ul>
        <br/>
      <p><strong>How We Use Your Information</strong></p>
      <p>We use your information, including personal and business information, to:</p>
      <ul>
        <li>Provide, operate, and maintain our Services</li>
        <li>Improve, personalize, and expand our Services</li>
        <li>Understand and analyze how you use our Services</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Service, and for marketing and promotional purposes</li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>
      </ul>
        <br/>
      <p><strong>How We Share Your Information</strong></p>
      <p>We may share your information with third-party service providers, business partners, and affiliated entities that assist us in providing our Services, including:</p>
      <ul>
        <li>Payment processors</li>
        <li>Cloud storage providers</li>
        <li>Customer service vendors</li>
        <li>Marketing and analytics partners</li>
      </ul>
    <br/>
      <p><strong>Data Security</strong></p>
      <p>We implement a variety of security measures to maintain the safety of your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>

      <p><strong>Your Privacy Rights</strong></p>
      <p>Depending on your location and subject to applicable law, you may have the following rights with respect to your personal information:</p>
      <ul>
        <li>The right to request access to and receive information about the personal information we maintain about you</li>
        <li>The right to rectify inaccurate personal information or complete incomplete personal information</li>
        <li>The right to request the deletion of your personal information</li>
        <li>The right to request the restriction of the processing of your personal information</li>
        <li>The right to object to the processing of your personal information</li>
      </ul>
    <br/>
      <p><strong>Changes to This Privacy Policy</strong></p>
      <p>We may update this Privacy Policy from time to time in response to changing legal, technical, or business developments. When we update our Privacy Policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make.</p>

      <p><strong>Contact Us</strong></p>
      <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
      <ul>
        <li>Email: <a href="mailto:mail@spacepe.in">mail@spacepe.in</a></li>
        <li>Phone: +91 7291909636</li>
        <li>Address: Plot no. 190, 3rd Floor, Pocket A2, Sector 17, Golf Course Road, Dwarka, New Delhi -110075</li>
      </ul>
      <p>This Privacy Policy is effective as of 2nd January, 2024 and will be updated as necessary to reflect changes in our practices, the services we offer, and legal or regulatory developments.</p>
    </div>
  );
}

export default PrivacyPolicy;
