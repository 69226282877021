import React from 'react';
import "./ContactUs.css"
const ContactUs = () => {
  return (
    <div className="contact-us"> 
      <h2>Contact Information</h2>
      <ul>
        <li><strong>Email:</strong> <a href="mailto:mail@spacepe.in">mail@spacepe.in</a></li>
        <li><strong>Phone:</strong> <a href="tel:+917291909636">+91 7291909636</a></li>
        <li><strong>Address:</strong> Plot no. 190, 3rd Floor, Pocket A2, Sector 17, Golf Course Road, Dwarka, New Delhi -110075</li>
      </ul>
        
    </div>
  );
}

export default ContactUs;