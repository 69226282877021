import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { uiActions } from "../../store/uiaction-slice";
import { forgetPassword, verifyUser } from "../../store/user-actions";


const VerifyUser = ({match}) => {
const { userId } = match.params;
const { search } = useLocation();
const queryParams = new URLSearchParams(search);
const key = queryParams.get('key');
console.log(userId, key)
const dispatch = useDispatch();
const handleVerify = ()=>{

    dispatch(verifyUser(userId,key))
}


  return (
    <div>
        <br/>
        <br/>   <br/>   <br/>   <br/>   <br/>
        
        
       <button className="LoginBtn" style={{width:"50%", marginLeft:"22%"}} onClick={handleVerify}> Click here to verify</button>
       
       </div>
  )
}

export default VerifyUser