import { useState } from "react"
import "./Support.css"
import { useDispatch } from "react-redux"
import { sendIssue } from "../../store/issue-actions"

export default function Support(){
    const [username,setUsername] = useState("")
    const [emailId,setEmailId] = useState("")
    const [userIssue,setUserIssue] = useState("")
    const [file, setFile] = useState();
    const [data,setData] = useState({})
    const dispatch = useDispatch()


    const handleImgUpload = (e) => {
      e.preventDefault();
      setFile(URL.createObjectURL(e.target.files[0]));
      console.log(file)
    }
    const handleOnClick = (e) =>{
        e.preventDefault();
        const newOject = {...data}
        newOject.name = username;
        newOject.email = emailId;
        newOject.issue = userIssue;
        setData({...newOject});
        dispatch(sendIssue(data,setUsername,setEmailId,setUserIssue))

    }
    return (
        <section className="supportMainSection">
          <h1>Support</h1>
          <div className="supportInputsParent">
              <div className="supportInputs">
                  <span className="supportInput">
                    <label>Name * </label>
                    <input type="text" placeholder="Username..." value={username} onChange={(e) => setUsername(e.target.value)}/>
                  </span>
                  

                  <span className="supportInput">
                    <label>Email * </label>
                    <input type="text" placeholder="Email..." value={emailId} onChange={(e)=> setEmailId(e.target.value)}/>
                  </span>
                  


                  <span className="supportInput">
                    {/* <label>Attactment (Optional)</label> */}
                    {/* <input type="file" onChange={handleImgUpload}/> */}
                  </span>
                  
                  


                  
              </div>
          
            <img src={file}/>

                <div className="issueArea">
                  {/* <span className="supportInput"> */}
                        
                        <textarea placeholder="Decribe Your Issue" value={userIssue} onChange={(e)=> setUserIssue(e.target.value)}></textarea>
                  {/* </span> */}
                </div>
          </div>

          <div className="submitButton"><button onClick={handleOnClick}>Submit Issue</button></div>
        </section>
    )
}