

import axios from "axios"


const headers = { "Content-Type": "application/json" ,
"Authorization": "bearer " + localStorage.getItem("accessToken")
    }
const baseUrl = "http://api.spacepe.in" + "/api/"
const axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 100000,
    headers,
});

export default axiosInstance
