import { useState } from "react"
import { Link } from "react-router-dom"
import "./Footer.css"
import { useDispatch } from "react-redux"
import { subscribeNewsLetter } from "../../store/issue-actions"


export default function Footer() {
    const [newsletterText, setNewsletterText] = useState("")
    const dispatch = useDispatch()
    const handleSubscribe = (e) =>{
        e.preventDefault()
        
        console.log('---------->>>>>')
        dispatch(subscribeNewsLetter({email:newsletterText}))


    }
    return (
        <div className="footerSection">
        <div className="footer">
            <form className="newsLetter" onSubmit={handleSubscribe}>
                <input
                    type="text"
                    className="newsletterInput"
                    placeholder="Subscribe Newsletter With Email"
                    value={newsletterText}
                    onChange={(e) => setNewsletterText(e.target.value)}
                />
                <button className="newsletterBtn" type="submit" value="Submit">Subscribe</button>
            </form>
            <div className="footerColumns">
                <div className="footerProductsAndServices">
                    <div className="footerColumnsHead">Products & Services</div>
                    <Link className="link" to="/products/hrms">HRMS</Link>
                    <Link className="link" to="/products/crm">CRM</Link>
                    <Link className="link" to="/products/dataProcessingExcellence">Data Processing Excellence</Link>
                    <Link className="link" to="/products/consultancyServices">Consultancy Services</Link>
                </div>
                <div className="footerLegalPages">
                    <div className="footerColumnsHead">Legal Pages</div>
                    <Link className="link" to="/about-us">About Us</Link>
                    <Link className="link" to="/terms-&-conditions">Terms & Conditions</Link>
                    <Link className="link" to="/cancellations-&-refund">Cancellation & Refund</Link>
                    <Link className="link" to="/privacy-policy">Privacy Policy</Link>
                </div>
            </div>
        </div>
        <div className="subFooter">
            <div className="subHeadFirst">Powered By SpacePe </div>
            <div className="subHeadSecond"> Copyright © 2023 | All Right Reserved</div>
        </div>
        </div>
    )
}