import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allPlanData: [],
    isAuth: false,
    plan: {},
    message: "",
}   

const planslice =  createSlice(
    {

        name:'plan',
        initialState,
        reducers:{
            getAllPlans(state,action){
             state.allPlanData = action.payload.data
            }
        }
    }

)

export const planAction = planslice.actions;
export default planslice;
