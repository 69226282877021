import { configureStore } from '@reduxjs/toolkit'
import userslice from "./user-slice"
import planslice from './plans-slice'
import uiactionSlice from './uiaction-slice'
import issueSlice from './issue-slice'


export default configureStore({
    reducer: {
        user: userslice.reducer,
        plan: planslice.reducer,
        uiaction: uiactionSlice.reducer,
        issue:issueSlice.reducer
    },
})
