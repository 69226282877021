import { useContext, useState } from "react";
import axiosInstance from "../../helper/AxiosInstance";
import { viewContext } from "./Issues";
import { useDispatch } from "react-redux";
import { resolveIssue } from "../../store/issue-actions";
export default function IssueResolve() {
    const {Id,changeView} = useContext(viewContext)
    const queryId = Id;
    const [resol, setResol] = useState("");
    const disptach = useDispatch()
    const handleResolBtn = async (e) => {
        e.preventDefault();
        const data = {
            query_id: queryId,
            resolution: resol,
        }
        disptach(resolveIssue(data,setResol))
    }
    const closeView = (e) =>{
        e.preventDefault();
        changeView();
    }
    return (
        <>
            <section className="issueResolveMainSection">
                <div className="issueResolveProblemImageDiv">
                    <h2>Problem Image</h2>
                    <div className="issueImage">
                        {/* <img src={image} alt="Image" /> */}
                    </div>
                </div>
                


                <div className="resolutionMainDiv">
                    <div className="resolutionHeading">
                        Write Resolution
                    </div>
                    
                    <textarea placeholder="Start Writing Resolution" cols={70} rows={10} value={resol} onChange={(e) => setResol(e.target.value)}></textarea>
                    
                    <div className="resolutionButtons">
                        <span className="goBackButton"><button onClick={closeView}>Go Back</button></span>
                        <span className="sendResolutionButton"><button onClick={handleResolBtn}>Send Resolution</button></span>
                    </div>
                </div>
            </section>

        </>
    )
}