import { useEffect, useState ,createContext} from "react";
import axiosInstance from "../../helper/AxiosInstance";
import IssueResolve from "./IssueResolve";
import "./issues.css"
import { useDispatch, useSelector } from "react-redux";
import { getAllissue } from "../../store/issue-actions";


export const viewContext = createContext({})

export default function Issues() {
 
  // const [issues, setIssues] = useState([]);
  const [view,setView] = useState(false);
  const [Id,setId] = useState("");
  const [image,setImage] = useState("")
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getAllissue())
  },[])

  const issues = useSelector(state=>state.issue.allIssues)


  const changeView = () => {
    setView((prev) => !prev)
  }
  return (
    <section className="issuesSection">
    <h1 className="issuesHeading">Issues</h1>
    { (!view) &&
      <ul className="allIssues">
        {issues.map((issue) => (
          <li key={issue._id}>
            <ul>
              <li>Name : {issue.name}</li>
              <li>Email : {issue.email}</li>
              <li>Issue : {issue.issue}</li>

              <button onClick={()=>{
                setId(issue._id)
                // setImage(issue.img)  
                setView((prev)=>!prev)
            }
            }>View Issue</button>
            </ul>
          </li>
        ))}
      </ul>}
      <viewContext.Provider value={{Id,changeView,image}}>
    {(view) && <IssueResolve />}
    </viewContext.Provider>
    </section>
  );

}