import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import AccessDenied from "../components/AccessDenied/AccessDenied";

const ProtectedRoute = (route) => {
  const { user } = useSelector((state) => state.user);
  var permittedRole = user.roles;
  let isProtected = true;
  if (route.permittedUser.includes("admin")){
    isProtected = false
    if (route.permittedUser.includes(permittedRole)){
      isProtected = true
    }

  }


  return (
    <Route
      path={route.path}
      render={(props) =>
        isProtected ? <route.component {...props} /> : <AccessDenied />
      }
    ></Route>
  );
};

export default ProtectedRoute;
