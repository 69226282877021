import React from 'react'
import LoadingSpinner from '../../pages/LoadingSpinner '
const Registration = () => {
  return (
    <div>Check Page
      <LoadingSpinner/>
    </div>
  )
}

export default Registration