import "./Login.css"
import { useState } from "react";
import { useDispatch } from "react-redux";

import "./Login.css";
import { isAuthentication, requestForgetPassword } from "../../store/user-actions";
import { Link, useHistory } from "react-router-dom";
import { uiActions } from "../../store/uiaction-slice";
import { useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import axios from "axios";
export default function Login() {
  console.log(process.env.CLIENT_ID)
  const history = useHistory()
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const loginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const data = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo',{
        headers:{
          Authorization:`Bearer ${tokenResponse.access_token}`
        }
      })
      console.log(data.data,"=====>>>>>>>>")

      
    },
    onError: () => {
      console.log("error is coming=====>");
    },
  });
  
  const handleLogin = async () => {
    console.log("authentication we need to do" ,username , password)
    await dispatch(isAuthentication(username,password)).then((res) => {
      if (res) {
        history.push("/");
      }
      
    });
  };
  const handleForgot = async () => {
    if (username === ""){
      dispatch(uiActions.showNotification(
        {
          status: 'failure',
          message: "Please Enter Email id"
        }))
    }
    else{
      await dispatch(requestForgetPassword(username)).then((res) => {
        if (res) {
          history.push("/");
        }
        
      });
    }
  };

  return (
    <section className="loginSection">
      <section className="loginBox">
        <section className="loginDetails">

          <section className="loginDetailsSection">
            <span className="loginDetailsSectionHead">Login To SpacePe</span>
            <span className="loginDetailsSectionSubhead">Login with Email</span>
            <div className="LoginInput">
              <span>Email</span>
              <input type="email" name="email" placeholder="Email" value={username} id="login-username"
                  onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div className="LoginInput">
              <span>Password</span>
              <input type="password" name="password" placeholder="Please Enter Your Password" value={password} id="login-password"
                 onChange={(e) => setPassword(e.target.value)}></input>
            </div>
            <button className="LoginBtn" onClick={handleLogin}>Login</button>
            <button className="forgot-password-button" onClick={handleForgot}>Forgot Password</button>

            <div className="loginSeperate">--Or--</div>
            <span className="loginDetailsSectionSubhead" >Continue with Google</span>
            <button className="LoginBtn" onClick={()=>loginGoogle()}>Login With Google</button>           
          <div className="AlternativeOption">Don't Have An Account?
          <Link class="sign-up" data-testid="login-link" to="/signup">Sign up</Link>
           </div>
          </section>
         
        </section>
        <section className="loginImg">
          <img src="https://www.indiigo.in/assets/images/login.png" alt="img" />
        </section>
      </section>
    </section>
  )
}
