
import { Fragment, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

// import { Route, Routes, Switch, useLocation } from "react-router-dom";
import routes from "./routes/index";
import ProtectedRoute from "./routes/ProtectedRoute";
import Layout from "./pages/Layout";
import './App.css';
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import PopupNotification from "./pages/PopupNotification";
import { useSelector, useDispatch } from "react-redux";
import { getUserData } from "./store/user-actions";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";


function App() {

  const location = useLocation();
  const isforgot = location.pathname.includes("forget/")?true:false

  const message = useSelector(state=>state.uiaction.message)
  const dispatch = useDispatch()
  useEffect(()=>{
    if(localStorage.getItem("accessToken")) {
      dispatch(getUserData())
    }
  })
  return (
    <Fragment>
      {isforgot && <Route exact path="/forget/:userId" component={ForgetPassword} />}
      {message && <PopupNotification message={message.message} status={message.status}/>}
      {!isforgot && (
        <Layout>
          <Navbar/>
          <Switch>
            {routes.map((route, index) => (
              <ProtectedRoute key={index} exact path={route.path} {...route} />
            ))}
          </Switch>
          <Footer/>
        </Layout>
      )}
    </Fragment>
  );
}

export default App;



// things we need to do 
// Login management - >  create user , create admin, w=logout handle,     User login , manage state (is either user is logged in or not)

// loader when user click on buy plan , when some issue come , pop up the notification
// need to handle , if user exactly
// user icon should behaive like button
// 




