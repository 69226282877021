import React, { useState } from 'react';
import './UserOrders.css'
const UserOrders = () => {
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowSelect = (rowId) => {
    setSelectedRow(rowId);
  };

  const tableData = [
    { id: 1, orderID: 1231, period: '6 Months', active: 'Yes', name: 'plan 444', amount: 1231, purchasedDate: '01/02/31', emiPaid: '2/5' },
    { id: 2, orderID: 1231, period: '6 Months', active: 'Yes', name: 'plan 444', amount: 1231, purchasedDate: '01/02/31', emiPaid: '2/5' },

  ];

  return (
    <div className="userOrderDiv">
      <table className="userOrderTable">
        <thead>
          <tr>
            <td>Select</td>
            <td>OrderID</td>
            <td>Period</td>
            <td>Active</td>
            <td>Name</td>
            <td>Amount</td>
            <td>Purchased Date</td>
            <td>Emi Paid</td>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <tr key={row.id}>
              <td>
                <input
                  type="radio"
                  name="rowSelector"
                  checked={selectedRow === row.id}
                  onChange={() => handleRowSelect(row.id)}
                />
              </td>
              <td>{row.orderID}</td>
              <td>{row.period}</td>
              <td>{row.active}</td>
              <td>{row.name}</td>
              <td>{row.amount}</td>
              <td>{row.purchasedDate}</td>
              <td>{row.emiPaid}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        {selectedRow !== null && (
          <p>Selected Row: {selectedRow}</p>
        )}
      </div>
    </div>
  );
};

export default UserOrders;
