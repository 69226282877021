import {React, useState} from 'react'
import { useDispatch } from 'react-redux';
import { uiActions } from '../../store/uiaction-slice';
import { changePassword } from '../../store/user-actions';
const ChangePassword = () => {

    // const history = useHistory()
    const dispatch = useDispatch();
    const [rePassword, setRePassword] = useState("");
    const [password, setPassword] = useState("");
    const [current, setCurrent] = useState("");
    const handleChangePass =()=>{
        if ((password != rePassword)) {
            dispatch(uiActions.showNotification(
              {
                status: 'failure',
                message: "Password does not match"
              }
            ))
          }
          else if (password == '') {
            dispatch(uiActions.showNotification(
              {
                status: 'failure',
                message: "Please Enter password"
              }))
          }
          else {
            const data = {
              password: current,
              newpassword: password,
              
            }
            dispatch(changePassword(data));
            setPassword("")
            setRePassword("")
          }
    }

  return (
    <section className="forgotSection">
      <section className="loginBox">
        <section className="loginDetails">

          <section className="loginDetailsSection">
            <span className="loginDetailsSectionHead">Change Password</span>
            <div className="LoginInput">
              <span>Current</span>
              <input type="password" name="password" placeholder="Please Enter Your current Password" value={current} id="login-password"
                onChange={(e) => setCurrent(e.target.value)}></input>
            </div>
            <div className="LoginInput">
              <span>New Password</span>
              <input type="password" name="password" placeholder="Please Enter Your Password" value={password} id="login-password"
                onChange={(e) => setPassword(e.target.value)}></input>
            </div>
            <div className="LoginInput">
              <span>Re-Enter</span>
              <input type="rePassword" name="rePassword" placeholder="Please Enter Again" value={rePassword} id="login-password"
                onChange={(e) => setRePassword(e.target.value)}></input>
            </div>
            <button className="LoginBtn" onClick={handleChangePass}>Set Password</button>

          </section>

        </section>
        <section className="loginImg">
          <img src="https://www.indiigo.in/assets/images/login.png" alt="img" />
        </section>
      </section>
    </section>
  )
}

export default ChangePassword