
import { useState, useEffect } from "react"
import "./Plans.css"
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import axiosInstance from "../../helper/AxiosInstance";
import { changePlanStatus, getAllPlanData } from '../../store/plans-actions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../../pages/LoadingSpinner ";
import { useLocation } from "react-router-dom";
import { uiActions } from "../../store/uiaction-slice";

let toggle_button = document.querySelector(".toggleText");
let toggle_fn = () => {
  if (toggle_button.innerHTML == "Monthly") {
    alert("Hello");
  }
}

export default function Plans() {


  // Month And Yearly Switch Toggle
  // Month And Yearly Switch Toggle
  useEffect(() => {
    let monthlyButton = document.querySelector("#toggleMonthlyBtn");
    let yearlyButton = document.querySelector("#toggleYearlyBtn");
  
    const handleMonthlyClick = () => {
      monthlyButton.classList.add("toggleTextClicked");
      yearlyButton.classList.remove("toggleTextClicked");
    };
  
    const handleYearlyClick = () => {
      monthlyButton.classList.remove("toggleTextClicked");
      yearlyButton.classList.add("toggleTextClicked");
    };
  
    monthlyButton.addEventListener("click", handleMonthlyClick);
    yearlyButton.addEventListener("click", handleYearlyClick);
  
    return () => {
      // Remove the specific click event listeners
      monthlyButton.removeEventListener('click', handleMonthlyClick);
      yearlyButton.removeEventListener('click', handleYearlyClick);
    };
  }, []);
  
  const [monthlyToggle, setMonthlyToggle] = useState(true);
  const dispatch = useDispatch()
  const history = useHistory();
  const user= useSelector(state=>state.user.user)
  const [loading, setLoading] = useState(false);
  const location = useLocation()
  const plans = useSelector(state => state.plan.allPlanData);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const changeStatus = (id) => {
    dispatch(changePlanStatus(id))
  }



  useEffect(() => {

    dispatch(getAllPlanData())


  }, [])
  useEffect(() => {
    const loadRazorpayScript = async () => {
      const scriptLoaded = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (scriptLoaded) {
        console.log("Razorpay script loaded successfully!");
      } else {
        console.error("Failed to load Razorpay script");
      }
    };
    loadRazorpayScript();
  }, []);
  const handlerazorpay = async (orderDetails) => {
    try{
      setLoading(true)
      console.log(orderDetails, "order details===>", loading)
      
      const createSubcription  = await axiosInstance.post("/subscribe",
              {
                plan_id:orderDetails._id
              })
      console.log(createSubcription,"create order ====")
      
      const options = {
        key: createSubcription.data.data.razor_key,
        subscription_id: createSubcription.data.data.subscription_id,
        name: createSubcription.data.data.name,
        description:  orderDetails.notes,
        currency: createSubcription.data.data.currency,
        amount: createSubcription.data.data.amount,
        image: "https://spacepe.in/images/logo.png",
        handler: async function (response) {
          console.log("Payment success:", response);
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          let forPush = null
          const isSuccessed  = await axiosInstance.post("/callback",
          {
            "razorpay_payment_id":response.razorpay_payment_id,
            "razorpay_subscription_id":response.razorpay_subscription_id,
            "razorpay_signature":response.razorpay_signature
            })
            console.log(isSuccessed,"response from call back",isSuccessed.data.data.paymentauthentic)
            if (isSuccessed.data.data.paymentauthentic){
              console.log("before")
              history.push("/paymentSuccess")
            }
            else{
              console.log("before.......")
              history.push("/paymentFailure")
            }
  
  
        },
        prefill: {
          name: createSubcription.data.data.name,
          email: createSubcription.data.data.email,
          contact: createSubcription.data.data.contact
        },
      };
      console.log(options,"open window")
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
      setLoading(false);
  
    }
    catch(err){
      console.log(err,"===========>>")
      setLoading(false)
      dispatch(uiActions.showNotification(
        {
          status: 'failure',
          message: err.message
        }
      ))

    }
    

  }
  
  
  const handleCloseLoadingModal = () => {
    console.log("click to close ")
    setLoading(false);
  };
  if(user !== "admin" && location.pathname === "/admin"){

  return (
    <div className="plansSection">
        {loading && <LoadingSpinner  onClose={handleCloseLoadingModal} />}
      <div className="plansToggle">
        <button className="plansToggleBtn">
          <div className="toggleText toggleTextClicked" id="toggleMonthlyBtn" onClick={() => setMonthlyToggle((prev) => !prev)}>Monthly</div>
          <div className="toggleText" id="toggleYearlyBtn" onClick={() => setMonthlyToggle((prev) => !prev)}>Annually</div>
        </button>
      </div>
      <div className="plansCardSection">
        {monthlyToggle ? plans.filter((i) => (i.period === "monthly")).map((i) => {
          return (<div className="plansCard" key={i.plan_id}>

            <div className="card-circle"></div>

            {/* <div className="say"><span>{i.say}</span><span>{i.offer}</span></div> */}
            <div className="plansCardHead">
              <div className="plansCardHeading">{i.notes && i.notes.heading}</div>
              <div className="plansCardSubHeading">{i.notes && i.notes['sub-heading']}</div>
            </div>

            <div className="plansCardStatus-Pricing">
              <div className="plansCardStatus">Monthly</div>
              <div className="plansCardPricing">{i.amount}/-</div>
            </div>
            <div className="plansCardFeatures">


              {Object.entries(i.notes).map(([l, v]) => (


                <div key={l} className="plansCardFeature"><TaskAltIcon />{v}</div>
              )
              )}
            </div>
            <div className="plansStatus">
              Status : {i.active === true ? <span>Active</span> : <span>
                In-Active
                </span>}
            </div>
            <button className="changePlanStatus" onClick={()=>changeStatus(i._id)}>Change Status</button>
          </div>)
        })
          :

          plans.filter((i) => (i.period === "yearly")).map((i) => {
            return (<div className="plansCard" key={i.plan_id}>

              <div className="card-circle"></div>

              {/* <div className="say"><span>{i.say}</span><span>{i.offer}</span></div> */}
              <div className="plansCardHead">
                <div className="plansCardHeading">{i.notes && i.notes.heading}</div>
                <div className="plansCardSubHeading">{i.notes && i.notes['sub-heading']}</div>
              </div>

              <div className="plansCardStatus-Pricing">
                <div className="plansCardStatus">Anually</div>
                <div className="plansCardPricing">{i.amount}/-</div>
              </div>
              <div className="plansCardFeatures">


                {Object.entries(i.notes).map(([l, v]) => (


                  <div key={l} className="plansCardFeature"><TaskAltIcon />{v}</div>
                )
                )}
              </div>
              <div className="plansStatus">
              Status : {i.active === true ? <span>Active</span> : <span>In-Active</span>}
            </div>
            </div>)
          })}
      </div>
    </div>
  )}
  else{
    return (
      <div className="plansSection">
          {loading && <LoadingSpinner  onClose={handleCloseLoadingModal} />}
        <div className="plansHeader">
          <div className="plansHeaderSlogan">Let's Choose <br /> Perfect Plan For You</div>
          <div className="plansHeaderImage"><img src="https://www.drupalcamppa.org/wp-content/uploads/2023/05/BJK-University.png" alt="Image" /></div>
        </div>
        <div className="plansSubheader">Choose Subscription & Plan <br /> Adopt Perfection</div>
        <div className="plansToggle">
          <button className="plansToggleBtn">
            <div className="toggleText toggleTextClicked" id="toggleMonthlyBtn" onClick={() => setMonthlyToggle((prev) => !prev)}>Monthly</div>
            <div className="toggleText" id="toggleYearlyBtn" onClick={() => setMonthlyToggle((prev) => !prev)}>Annually</div>
          </button>
        </div>
        <div className="plansCardSection">
          {monthlyToggle ? plans.filter((i) => (i.period === "monthly" && i.plan_active == true)).map((i) => {
            return (<div className="plansCard" key={i.plan_id}>
  
              <div className="card-circle"></div>
  
              {/* <div className="say"><span>{i.say}</span><span>{i.offer}</span></div> */}
              <div className="plansCardHead">
                <div className="plansCardHeading">{i.notes && i.notes.heading}</div>
                <div className="plansCardSubHeading">{i.notes && i.notes['sub-heading']}</div>
              </div>
  
              <div className="plansCardStatus-Pricing">
                <div className="plansCardStatus">Monthly</div>
                <div className="plansCardPricing">{i.amount}/-</div>
              </div>
              <div className="plansCardFeatures">
  
  
                {Object.entries(i.notes).map(([l, v]) => (
  
  
                  <div key={l} className="plansCardFeature"><TaskAltIcon />{v}</div>
                )
                )}
              </div>
              <div className="plansCheckoutBtn" onClick={() => handlerazorpay(i)}>
                Checkout
              </div>
            </div>)
          })
            :
  
            plans.filter((i) => (i.period === "yearly" && i.plan_active == true)).map((i) => {
              return (<div className="plansCard" key={i.plan_id}>
  
                <div className="card-circle"></div>
  
                {/* <div className="say"><span>{i.say}</span><span>{i.offer}</span></div> */}
                <div className="plansCardHead">
                  <div className="plansCardHeading">{i.notes && i.notes.heading}</div>
                  <div className="plansCardSubHeading">{i.notes && i.notes['sub-heading']}</div>
                </div>
  
                <div className="plansCardStatus-Pricing">
                  <div className="plansCardStatus">Anually</div>
                  <div className="plansCardPricing">{i.amount}/-</div>
                </div>
                <div className="plansCardFeatures">
  
  
                  {Object.entries(i.notes).map(([l, v]) => (
  
  
                    <div key={l} className="plansCardFeature"><TaskAltIcon />{v}</div>
                  )
                  )}
                </div>
                <div className="plansCheckoutBtn" onClick={() => handlerazorpay(i)}>
                  Checkout
                </div>
              </div>)
            })}
        </div>
      </div>)
  }
}