import React from 'react'
import "./Home.css";
import PopupNotification from '../../pages/PopupNotification'
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CodeIcon from '@mui/icons-material/Code';


const Home = () => {
  return (
    <div className='homeSection'>
      <div className='homeHeaderSection'>
        <div className='homeHeaderText'>
          <div><h3>Welcome to!</h3></div>
          <div><h1>SpacePe</h1></div>
        </div>

        <div className='homeHeaderCardSection'>
          <div className='homeHeaderCard homeHeaderCard1'>
            <div className='homeHeaderCardLogo'>
            <CloudDoneIcon className='CloudIcon'/>
            </div>
            <div className='homeHeaderCardSlogans'>
              <div className='homeHeaderCardHeading'>Data Processing</div>
              <div className='homeHeaderCardSubheading'>Fast Data Processing Excellence</div>
            </div>
            <div className='homeHeaderCardNote'>
              "In the era of big data, efficient data processing is paramount"
            </div>
            <button className='homeHeaderCardBtn'>Start Now</button>
          </div>





          {/* Second Card */}
          {/* Second Card */}
          <div className='homeHeaderCard homeHeaderCard2'>
            <div className='homeHeaderCardLogo'>
              <CodeIcon className='CloudIcon'/>
            </div>
            <div className='homeHeaderCardSlogans'>
              <div className='homeHeaderCardHeading'>IT Solutions</div>
              <div className='homeHeaderCardSubheading'>Comprehensive IT Solutions</div>
            </div>
            <div className='homeHeaderCardNote'>
              "SpacePe: Unleashling IT brilliance for your buisness success."
            </div>
            <button className='homeHeaderCardBtn'>Start Now</button>
          </div>
        </div>


        
      </div>
      <hr color='#00A3FF' width='65%'/>





      {/* Services */}
      {/* Services */}
      <div className='homeProductNServicesSection'>
        <div className='HomeServiceSection'>
        <div className='homePNSHeadings'>
          <div className='homePNSHead'>Products And Services</div>
          <div className='homePNSSubhead'>We Are Very Pationate About Our New Projects</div>
        </div>
        <div className='homePNSCardSection'>
          .
          <div className='homePNSCard'>
            <div className='homePNSCardHeadings'>
              <div className='homePNSCardHead'>
                HRMS
              </div>
              <div className='homePNSCardSubhead'>
                Human Resource Management Sytem
              </div>
            </div>
            <div className='homePNSCardPara'>
              In an era where dynamic workplace & diverse workforce needs are continuously evolving, our HRMS Project stands as a beacon of innovation & Efficiency...
            </div>
            <button className='homePNSCardBtn'>Know More</button>
          </div>
          <div className='homePNSCard'>
            <div className='homePNSCardHeadings'>
              <div className='homePNSCardHead'>
                CRM
              </div>
              <div className='homePNSCardSubhead'>
                Customer Relation ship Management
              </div>
            </div>
            <div className='homePNSCardPara'>
              Companies are continuously preparing itself to engage with customers to make better customer relationships, Now a days Here Are Huge Conc...
            </div>
            <button className='homePNSCardBtn'>Know More</button>
          </div>
          <div className='homePNSCard'>
            <div className='homePNSCardHeadings'>
              <div className='homePNSCardHead'>
                Consultancy Services
              </div>
              <div className='homePNSCardSubhead'>
                Professional IT Consultancy Services
              </div>
            </div>
            <div className='homePNSCardPara'>
              Our seasoned consultants are dedicated to guiding businesses through the intricate landscape of information technology. From strategic planning to implementation....
            </div>
            <button className='homePNSCardBtn'>Know More</button>
          </div>
          <div className='homePNSCard'>
            <div className='homePNSCardHeadings'>
              <div className='homePNSCardHead'>
                Financial Inclusions
              </div>
              <div className='homePNSCardSubhead'>
                Professional Financial Inclusions
              </div>
            </div>
            <div className='homePNSCardPara'>
              Embracing financial Inclusions, we create solutions that bridge gaps and provide access to financial services for all. Our initiatives empower individuals....
            </div>
            <button className='homePNSCardBtn'>Know More</button>
          </div>
          <div className='homePNSCard'>
            <div className='homePNSCardHeadings'>
              <div className='homePNSCardHead'>
                Data Processing Excellence
              </div>
              <div className='homePNSCardSubhead'>
                Fast Data Processing Excellence
              </div>
            </div>
            <div className='homePNSCardPara'>
              In the era of big data, efficient data processing is paramount. At SpacePe, we harness the power of advanced algorithms and technologies to process data swiftly...
            </div>
            <button className='homePNSCardBtn'>Know More</button>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Home