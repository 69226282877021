import React from 'react';
import "./CancellationsAndRefunds.css"
const CancellationsAndRefunds = () => {
  return (
    <div className="cancellations-and-refunds">
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <h2>Cancellations and Refunds</h2>

      <div className="policy-section">
        <h3>Refund Policy</h3>
        <p><strong>Introduction</strong></p>
        <p>SpacePe India Services LLP ("SpacePe," "we," "us," or "our") is committed to customer satisfaction. This Refund Policy outlines the circumstances under which SpacePe may provide refunds to our clients ("you") regarding our digital services, including storage services, Banking as a Service (BaaS), Enterprise Resource Planning (ERP), and software customization services.</p>
        <p><strong>Scope</strong></p>
        <p>This Refund Policy applies to all services offered by SpacePe, including:</p>
        <ul>
          <li>Storage Services</li>
          <li>Banking as a Service (BaaS)</li>
          <li>Enterprise Resource Planning (ERP) Solutions</li>
          <li>Software Customization Services</li>
        </ul>
        <br/>
        <p><strong>Refund Eligibility</strong></p>
        <p>Trial Period: We offer a trial for certain services. If you are not satisfied with the service within the trial period, you are eligible for a full refund.</p>
        <p>Service Disruption: If you experience a continuous and unresolved service disruption that significantly impacts your use of the service, not caused by external factors beyond our control, you may be eligible for a partial or full refund, depending on the severity and duration of the disruption.</p>
        <p>Project Cancellation (For Custom Projects): If a project involving ERP solutions or software customization is canceled:</p>
        <ul>
          <li>Before Work Begins: If cancellation occurs before project initiation, you may be eligible for a full refund of any prepaid fees.</li>
          <li>During Development: If the project is canceled during development, a refund will be considered on a case-by-case basis, taking into account the work completed and costs incurred up to the point of cancellation.</li>
        </ul>
        <p>Non-Delivery: If we fail to deliver the agreed-upon service within the specified timeframe due to reasons within our control, you are eligible for a full refund.</p>
        <p><strong>Non-Refundable Circumstances</strong></p>
        <p>Services that have been completed and delivered as per the agreement.</p>
        <p>Fees associated with the setup or customization of services, unless the project is canceled before work commences.</p>
        <p>Charges for any third-party services or products included in our services.</p>
        <p><strong>Requesting a Refund</strong></p>
        <p>Notification: To request a refund, you must notify us in writing within 5 days of the issue that leads to the request for a refund. Please include a detailed explanation of the reason for the refund request.</p>
        <p>Review Process: Upon receiving your request, we will review it within 2 business days. We may contact you to obtain additional information regarding the request.</p>
        <p>Outcome: After reviewing your request, we will inform you of our decision. If approved, we will process the refund according to your original method of payment within 15 business days.</p>
        <p><strong>Modifications to the Refund Policy</strong></p>
        <p>SpacePe reserves the right to modify this Refund Policy at any time. Any changes will become effective immediately upon posting on our website or through direct communication with you.</p>
      </div>

      <div className="policy-section">
        <h3>Cancellation Policy</h3>
        <p><strong>Introduction</strong></p>
        <p>SpacePe India Services LLP ("SpacePe," "we," "us," or "our") is committed to providing our clients ("you") with exceptional service. However, we understand that circumstances may change, leading you to decide to cancel our services. This Cancellation Policy outlines the terms and processes for canceling services provided by SpacePe, including storage solutions, Banking as a Service (BaaS), Enterprise Resource Planning (ERP), and software customization services.</p>
        <p><strong>Cancellation Terms</strong></p>
        <p>Notice Period: Clients wishing to cancel their service must provide SpacePe with a written notice of cancellation. The required notice period for cancellation is 30 days prior to the desired end date of services. This allows both parties ample time to ensure a smooth transition and closure of the account.</p>
        <p><strong>Cancellation Process</strong></p>
        <p>Notification: To initiate a cancellation, you must send a formal notice via email to mail@spacepe.in or through your dedicated account manager. The notice must include your account details, the service(s) you wish to cancel, and the desired cancellation date.</p>
        <p>Confirmation: Upon receiving your cancellation notice, we will contact you within 2 business days to confirm receipt and discuss the next steps, including any necessary transition services.</p>
        <p>Finalization: We will provide you with a Cancellation Confirmation document outlining the final service date, any outstanding obligations, and details regarding the removal of your data from our systems, if applicable.</p>
        <p><strong>Early Termination Fees</strong></p>
        <p>For clients under a fixed-term contract, early termination may incur fees. These fees are calculated based on the remaining contract term and the nature of the services provided. Specific details regarding early termination fees can be found in your service agreement.</p>
        <p><strong>Refunds</strong></p>
        <p>SpacePe will assess refund eligibility on a case-by-case basis, considering factors such as the nature of the service, usage prior to cancellation, and any prepayments. Refund details for specific services are outlined in the service agreement.</p>
        <p><strong>Data Retrieval and Deletion</strong></p>
        <p>Upon cancellation, clients will have 30 days to request the retrieval of their data, after which we will proceed with the secure deletion of all client data from our systems. Data retrieval and deletion processes will comply with our Data Protection and Privacy Policy to ensure the confidentiality and integrity of your information.</p>
        <p><strong>Modifications to the Policy</strong></p>
        <p>SpacePe reserves the right to modify this Cancellation Policy at any time. Any changes will be effective immediately upon posting on our website or by direct communication to you. Your continued use of our services after any changes indicates your acceptance of the new terms.</p>
      </div>
    </div>
  );
}

export default CancellationsAndRefunds;
