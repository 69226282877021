import React from 'react';
import "./PrintingDocument.css"

const PricingDocument = () => {
  return (
    <div className="pricing-document">
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <h2>Pricing Document</h2>

      <div className="policy-section">
        <h3>Introduction</h3>
        <p>At SpacePe India Services LLP, we specialize in providing bespoke digital transformation solutions that cater specifically to the unique needs of your business. Our comprehensive suite of services includes advanced storage solutions, Banking as a Service (BaaS), Enterprise Resource Planning (ERP), and specialized software customization services. Recognizing the diverse requirements of our clients, we are committed to offering customized pricing that aligns with your specific business goals and challenges.</p>
      </div>

      <div className="policy-section">
        <h3>Our Customized Pricing Approach</h3>
        <p><strong>Understanding Your Needs</strong><br/>Our first step is to gain a deep understanding of your business objectives, operational needs, and the challenges you face. This involves a detailed consultation with our team of experts to ensure that we can offer the most effective and efficient solution.</p>
        <p><strong>Tailored Solutions</strong><br/>Based on the insights gathered during our initial consultation, we will design a solution that addresses your specific requirements. Our services are highly adaptable, ensuring that whether you're a startup looking for scalable storage options, a fintech in need of a comprehensive BaaS platform, or a large corporation seeking an advanced ERP system, we can craft a solution that fits.</p>
        <p><strong>Customized Pricing</strong><br/>Once we have defined the scope of the solution, we will provide a customized pricing proposal. This proposal will reflect the complexity of the project, the technologies and resources involved, and the anticipated development and deployment timelines. Our pricing model is transparent and structured to ensure that you only pay for what you need, ensuring the best return on your investment.</p>
      </div>

      <div className="policy-section">
        <h3>Our Services</h3>
        <p><strong>Storage Services</strong><br/>Offering secure, scalable, and reliable storage solutions designed to grow with your business. Pricing is customized based on storage capacity needs, security requirements, and additional features.</p>
        <p><strong>Banking as a Service (BaaS)</strong><br/>Providing a robust platform for fintechs and financial institutions to launch and expand their banking services. Custom pricing is determined by the specific functionalities required, integration complexity, and support levels.</p>
        <p><strong>Enterprise Resource Planning (ERP)</strong><br/>Delivering comprehensive ERP solutions that streamline business processes across finance, HR, operations, and more. Our customized pricing takes into account the scale of the deployment, module customizations, and integration with existing systems.</p>
        <p><strong>Software Customization Services</strong><br/>Creating bespoke software solutions that perfectly fit your business workflows, processes, and goals. Pricing for software customization services is based on the project scope, development time, and specialized requirements.</p>
      </div>

      <div className="policy-section">
        <h3>Getting Started</h3>
        <p>To begin the process of transforming your business with SpacePe India Services LLP, please contact us to schedule a consultation. Our team is ready to work closely with you to understand your needs and provide a tailored solution that delivers real value to your business.</p>
      </div>

    </div>
  );
}

export default PricingDocument;
