import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    activeIssues:[],
    allIssues:[],
    userIssue:[]
}

const issueSlice = createSlice(
    {

        name:'issue',
        initialState,
        reducers:{
            getAllIssue(state,action){
             state.allIssues = action.payload.data
            },
            getActiveIssue(state,action){
                state.activeIssues = action.payload.data
               }
        }
    }
)


export const issueAction = issueSlice.actions;
export default issueSlice;
