
import React from 'react';
import { RotatingLines as Loader } from 'react-loader-spinner';


const LoadingSpinner = () => (
  <div  style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure the modal appears above other elements
  }} >
    <Loader strokeColor="grey"  alignItems= 'center'  />
  </div>
);

export default LoadingSpinner;
