import { colors } from "@mui/material";
import "./PaymentFailure.css"
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
export default function PaymentFailure(){
    return(
        <section className="paymentFailureSection">
            <section className="paymentFailureBox">
            <div className="paymentFailureImg">
                <img src="https://img.freepik.com/premium-vector/boy-is-successful-business_118167-5723.jpg?w=740" alt="Img" />
            </div>
            <div className="paymentFailureInfo">
                <span className="paymentFailureHead">!OOPS</span>
                <span className="paymentFailuresubhead">Payment Failure</span>
                <span className="paymentFailureId">
                    OrderId : 89HBGFSNFJH789
                    {/*Backend Data  */}
                </span>
            </div>
            <div className="paymentFailureErrorMsg"><span>
                Error : Slow Internet Speed 
                {/* Backend data */}
                </span></div>
            <div className="paymentFailureBtns">
            <button className="paymenntFailureDwnd  Btn"><span>Retry</span><span className="PaymentFailureRetryloadIcon"><ReplayOutlinedIcon /></span></button>
                {/* <button className="paymentFailureDwndBtn"><span>Retry</span></button> */}
            </div>
            </section>
        </section>
    )
}