import { issueAction } from "./issue-slice";
import axiosInstance from "../helper/AxiosInstance";
import { uiActions } from "./uiaction-slice";


export const sendIssue = (data,setUsername,setEmailId,setUserIssue) =>{
    return async (dispatch)=>{
        try {
            let response = null            
            await axiosInstance.post("user/query",data).then(res => {
                dispatch(uiActions.showNotification(
                    {
                        status: "success",
                        message: res.data.message
                    }
                ))
                setUsername("")
                setEmailId("")
                setUserIssue("")
            })
                
            return response
        } catch (error) {
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } 

    }
}

export const getAllissue = () =>{
    return async (dispatch)=>{
        try {
            let response = null            
            await axiosInstance.get("admin/queries").then(res => {
                dispatch(issueAction.getAllIssue(res.data))
            })
                
            return response
        } catch (error) {
            console.log(error, "eroor")
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } 

    }
}


export const resolveIssue = (data,setResol) =>{
    return async (dispatch)=>{
        try {
            let response = null            
            await axiosInstance.post("/admin/query/resolve",data).then(res => {
                dispatch(uiActions.showNotification(
                    {
                        status: "success",
                        message: res.data.message
                    }
                ))
                setResol("")
            })
                
            return response
        } catch (error) {
            console.log(error, "eroor")
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } 

    }
}

export const subscribeNewsLetter = (email) =>{
    return async (dispatch)=>{
        try {         
            const data = await axiosInstance.post("user/newsletter",email)
            if (data){
                dispatch(uiActions.showNotification({
                    status:"success",
                    message:"Thankyou for subscribing new letter"
                }))
            }
        } catch (error) {
            console.log(error, "eroor")
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error?.response?.data?.message || error?.message
                }

            ))
        } 

    }
}