import React from 'react';
import "./Shipping.css"

const Shipping = () => {
  return (
    <div className="shipping">
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      <h2>Software Shipping Policy</h2>

      <div className="policy-section">
        <h3>Introduction</h3>
        <p>Welcome to SpacePe India Services LLP ("SpacePe"). We specialize in providing top-tier digital services, including storage services, Banking as a Service (BaaS), Enterprise Resource Planning (ERP), and bespoke software customization services. This Digital Delivery Policy outlines our commitment to delivering these services efficiently and effectively to our clients.</p>
      </div>

      <div className="policy-section">
        <h3>Scope of Services</h3>
        <p>Our digital service offerings encompass the following:</p>
        <ul>
          <li>Storage Services: We offer cloud-based storage solutions that are secure, scalable, and designed to meet your business's unique needs.</li>
          <li>Banking as a Service (BaaS): Our BaaS platform provides a comprehensive suite of banking functionalities, enabling businesses to offer financial services seamlessly.</li>
          <li>Enterprise Resource Planning (ERP): We deliver ERP solutions that streamline business processes, enhance operational efficiency, and provide real-time insights.</li>
          <li>Software Customization Services: Our team specializes in developing customized software solutions tailored to your business requirements, ensuring flexibility and scalability.</li>
        </ul>
      </div>

      <div className="policy-section">
        <h3>Digital Delivery Process</h3>
        <p>Service Activation: Upon confirmation of your purchase and successful payment, you will receive an email acknowledgment. This email will contain detailed instructions on how to access the purchased services, including any necessary credentials or access keys.</p>
        <p>Implementation Schedule: For services requiring setup or customization, such as ERP solutions or customized software, our team will contact you to discuss the project timeline and milestones. A detailed implementation schedule will be provided, outlining the phases of deployment and any required client-side actions.</p>
        <p>Access to Digital Services: Services such as BaaS or cloud storage will be accessible immediately upon activation, barring any custom configurations. Instructions for use and management of these services will be included in your welcome package.</p>
        <p>Software Delivery: Custom software or ERP solutions will be delivered according to the agreed-upon schedule. Delivery will include all necessary installation files, documentation, and licensing information. Where applicable, deployment will be carried out remotely by our team, ensuring a seamless integration into your existing systems.</p>
        <p>Training and Support: To maximize the value of our services, training sessions may be provided remotely or through pre-recorded tutorials. Ongoing support will be available as specified in your service agreement, ensuring any issues are promptly addressed.</p>
      </div>

      <div className="policy-section">
        <h3>Changes and Updates</h3>
        <p>SpacePe reserves the right to update or modify this Digital Delivery Policy at any time without prior notice. Any changes will be effective immediately upon posting on our website or direct communication to you.</p>
      </div>

      <div className="policy-section">
        <h3>Contact Information</h3>
        <p>For any questions or concerns regarding our Digital Delivery Policy or your service delivery, please contact us at:</p>
        <p>Email: mail@spacepe.in</p>
        <p>Phone: +91 7291909636</p>
        <p>Address: Plot no. 190, 3rd Floor, Pocket A2, Sector 17, Golf Course Road, Dwarka, New Delhi -110075</p>
        <p>We are dedicated to ensuring that your experience with SpacePe India Services LLP is seamless and exceeds your expectations. Thank you for choosing SpacePe for your digital transformation needs.</p>
      </div>
    </div>
  );
}

export default Shipping;
