import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css"

import logo from "../../assests/logo_1.png"
// Legal Page Manually
// import TNCPage from "./PrivacyPolicy";
// Legal Page Manually


import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { purple } from "@mui/material/colors";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import UserManagement from "../UserManagement/UserManagement";




export default function Navbar(){
    // Toggle Side Menu
    useEffect(()=>{
        let toggleSideButton = document.querySelector("#sideToggleButton");
        let toggleSideMenu = document.querySelector("#toggleSideMenuSection");
        let toggleMenuClose = document.querySelector("#closeSideMenu");
        let toggleMenuLinks = document.querySelector(".toggleLinks");

        toggleSideButton.addEventListener("click", () => {
            toggleSideMenu.classList.remove("toggleSideMenu")
            toggleSideMenu.classList.add("sideMenuIn")
            toggleSideMenu.classList.remove("sideMenuOut")
        })

        toggleMenuClose.addEventListener("click", () => {
            toggleSideMenu.classList.remove("sideMenuIn")
            toggleSideMenu.classList.add("sideMenuOut")
        })

        toggleMenuLinks.addEventListener("click", () => {
            toggleSideMenu.classList.remove("sideMenuIn")
            toggleSideMenu.classList.add("sideMenuOut")
        })

        let changeSideMenu = () =>{
            toggleSideMenu.classList.remove("sideMenuIn")
            toggleSideMenu.classList.add("sideMenuOut")
        }
    })





    const [legalPageVisibility, setLegalPageVisibility] = useState(false);
    const show = () => {
        setLegalPageVisibility((prev) => (!prev));
    }
    
    const currentPagePath = useLocation()
    

    const legalBtnElements = [
        {
            to: "/PrivacyPolicy",
            btnText: "Privacy Policy"
        },
        {
            to: "/TermsAndConditions",
            btnText: "Terms & Conditions"
        },
        {
            to: "/CancellationsAndRefunds",
            btnText: "Cancellation & Refund"
        },
        {
            to: "/AboutUs",
            btnText: "About Us"
        },
        {
            to: "/shipping",
            btnText: "Shipping"
        },
        {
            to: "/printingDocument",
            btnText: "Printing Document"
        },
        {
            to: "/contactUs",
            btnText: "Contact Us"
        },
    ]

    
    return (

        

        <div className="navbarSection">
        <div className="Navbar">
            <div className="navLogo">
            <Link className="link" to="/">
                    
                <img src={logo} alt="SpacePe" id="SpacePeLogo"/>
            </Link>
            </div>
            <div className="navLinks">
                {/* <Link className="link" to="/">
                    <HomeIcon sx={{ color:"#6C156E"}} />
                </Link> */}
                {/* <Link className="link" to="/cart">
                    <ShoppingCartIcon sx={{ color:"#6C156E"}} />
                </Link> */}
                <UserManagement/>
                {/* <Link className="link" to="/profile">
                    <AccountCircleOutlinedIcon sx={{ color: "#6C156E" }} />
                </Link> */}

                <Link className="link"  to="/support">
                    <HelpOutlineOutlinedIcon  sx={{ color:"#000" }} />
                </Link>
                <div  className="legalContainer" onClick={show}><span className="Legalbtn link"><span>Legal</span>{legalPageVisibility ? <KeyboardArrowUpIcon sx={{ color: "black"}} /> : <KeyboardArrowDownOutlinedIcon sx={{ color: "black" }} />}</span>
                    {legalPageVisibility &&
                            <div className="legalSection link">
                                {legalBtnElements.filter((i)=>(i.to !== currentPagePath.pathname)).map((i)=>(
                                <Link className="legalPageLinks" key={i.to} to={i.to}>{i.btnText}</Link>
                                ))}
                            </div>
                    }
                </div>
                <Link className="link plansBtn" to="/plans">Look On Plans</Link>


                {/* Menu Toggle Button */}
                <span className="menuToggleButton"><MenuRoundedIcon className="sideToggleButton" id="sideToggleButton"/></span>
            </div>









{/* -------------------------- Toggle side Menu ------------------------ */}
        <section className="toggleSideMenuSection" id="toggleSideMenuSection">
                        <div className="ToggleMenu">
                        
                            <Link className="link toggleLinks"  to="/support">
                                <span>Need Support</span>
                            </Link>
                            {/* <div  className="legalContainer" onClick={show}><span className="Legalbtn link"><span>Legal</span>{legalPageVisibility ? <KeyboardArrowUpIcon sx={{ color: purple[700] }} /> : <KeyboardArrowDownOutlinedIcon sx={{ color: purple[700] }} />}</span>
                                {legalPageVisibility &&
                                        <div className="legalSection link">
                                            {legalBtnElements.filter((i)=>(i.to !== currentPagePath.pathname)).map((i)=>(
                                            <Link className="legalPageLinks" key={i.to} to={i.to}>{i.btnText}</Link>
                                            ))}
                                        </div>
                                }
                            </div> */}

                            {/* <Link to={<TNCPage/>}>d</Link> */}
                            <Link className="link plansBtn toggleLinks" to="/plans">Look On Plans</Link>
                            <Link className="link plansBtn toggleLinks" to="/TermsAndConditions">Terms And Conditions</Link>
                            <Link className="link plansBtn toggleLinks" to="/PrivacyPolicy">Privacy Policy</Link>
                            <Link className="link plansBtn toggleLinks" to="/Cancellation And Refund">Cancellation And Refund</Link>
                        </div> 
                        <span className="closeSideMenu" id="closeSideMenu">Close <ChevronRightRoundedIcon/></span>
        </section>
        </div>
        </div>
    )
}