import { useEffect, useState } from "react"
import CreatePlan from "../CreatePlan/CreatePlan";
import CreateAdmin from "../CreateAdmin/CreateAdmin";
import Issues from "../Issues/Issues";
import EditPlan from "../EditPlan/EditPlan";
import "./AdminDashboard.css";

export default function AdminDashboard() {
    const [accessKey, setAccessKey] = useState(1);

    return (
        <section className="AdminDashboardSection">
            <section className="AdminDasboardBox">
                <section className="AdminDashboardAccessBtns">
                    <button className={accessKey === 1 ? 'AdminDashboardBtn clickedButton':'AdminDashboardBtn '} id="createPlanButton" onClick={() => setAccessKey(1)}>Create Plan</button>
                    <button className={accessKey === 2 ? 'AdminDashboardBtn clickedButton' :'AdminDashboardBtn'} id="issuesButton" onClick={() => setAccessKey(2)}>Issues</button>
                    <button className={accessKey === 3 ? 'AdminDashboardBtn clickedButton' :'AdminDashboardBtn'} id="createAdminButton" onClick={() => setAccessKey(3)}>Create Admin</button>
                    <button className={accessKey === 4 ? 'AdminDashboardBtn clickedButton' :'AdminDashboardBtn'} id="editPlanButton" onClick={() => setAccessKey(4)}>Edit Plan</button>
                </section>

                <section className="AdminDasboardComponents">
                        {(accessKey === 1) && <CreatePlan />}
                        {(accessKey === 2) && <Issues/>}
                        {(accessKey === 3) && <CreateAdmin />}
                        {(accessKey === 4) && <EditPlan />}
                </section>

            </section>
        </section>
        
    )
}

