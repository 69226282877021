
import { userActions } from "./user-slice";
import axiosInstance from "../helper/AxiosInstance";
import { uiActions } from "./uiaction-slice";

export const isAuthentication = (username, password) => {
    return async (dispatch) => {
        try {
            console.log("hitting Data with cred ==", username, password)

            const data = await axiosInstance.post('/user/login', {
                email: username,
                password: password
            });
            console.log(data.data.data.user, "==userrerr==")

            localStorage.setItem("accessToken", data.data.data.token)
            localStorage.setItem("user", btoa(JSON.stringify(data.data.data.user)))

            console.log("setting up local Storage")
            await dispatch(userActions.getUserData(data.data.data.user));
            return data

        } catch (error) {
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } finally {
            console.log("in finnallly ")
        }
    };
}

export const getAllUserData = (pageNo = 1, limit = 10) => {
    return async (dispatch) => {
        try {

            const data = await axiosInstance.get('user');
            console.log(data, "====")
            await dispatch(userActions.getAllUserData(data.data));
        } catch (error) {
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } finally {
            console.log("in finnallly ")
        }
    };
};

export const registerUser = (userDetails) => {
    return async (dispatch) => {
        try {

            let response = null
            await axiosInstance.post('user/register', userDetails).then(res => {
                console.log(res.status)
                if (res.status == 200) {
                    response = res
                    dispatch(uiActions.showNotification(
                        {
                          status: 'success',
                          message: "Register successfull"
                        }
                
                      ))
                }
            })
            return response


            // await dispatch(userActions.getUserData(data.data));
        } catch (error) {
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
            return error
        } finally {
            console.log("in finnallly ")
        }
    };
}

export const getUserData = () => {
    return async (dispatch) => {
        try {
            const data = localStorage.getItem("user")
            if(data){

                await dispatch(userActions.setUserData(JSON.parse(atob(data))));
            }

        } catch (error) {
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } finally {
            console.log("in finnallly ")
        }
    };
}
export const userLogout = () => {
    return async (dispatch) => {
        try {
            console.log("Logging out user")

            // const data = await axiosInstance.get('/')
            // await dispatch(userActions.getUserData(data.data));

            localStorage.clear()
            // await dispatch(userActions.setUserData({})) - to set user data = null
        } catch (error) {
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } finally {
            //  logout option should  change in to login
            console.log("in finnallly ")
        }
    };
}

export const forgetPassword = (password) => {
    return async (dispatch) => {
        try {

            let response = null
            await axiosInstance.post('/user/password/forget/change', password).then(res => {
                dispatch(uiActions.showNotification(
                    {
                        status:'success',
                        message: res.data.message
                    }

                ))
            }
            )
        } catch (error) {
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } finally {
            console.log("in finnallly ")
        }
    };
}

export const changePassword = (password) => {
    return async (dispatch) => {
        try {

            let response = null
            await axiosInstance.post('/user/password/change',password).then(res => {
                dispatch(uiActions.showNotification(
                    {
                        status:"success",
                        message: res.data.message
                    }
                ))
            })
            return response
        } catch (error) {
            console.log(error, "eroor")
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }))
        } finally {
            console.log("in finnallly ")
        }
    };
}

export const verifyUser = (userId, key) => {
    return async (dispatch) => {
        try {
            let response = null
            console.log('console.log')
            await axiosInstance.post('/user/email/verify',
                {
                    "user_id": userId,
                    "token": key
                }
            ).then(res => {
                console.log(res, "ress==>>>>")
                dispatch(uiActions.showNotification(
                    {
                        status: "success",
                        message: res.data.message
                    }
                ))})
            return response
        } catch (error) {
            console.log(error, "eroor")
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } finally {
            console.log("in finnallly ")
        }
    };
}

export const requestForgetPassword = (userEmail) => {
    return async (dispatch) => {
        try {

            let response = null
            await axiosInstance.post('/user/password/forget', {
                email:userEmail
            }).then(res => {
                dispatch(uiActions.showNotification(
                    {
                        status: 'success',
                        message: res.data.message
                    }

                ))
            }
            )
            return response
        } catch (error) {
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } finally {
            console.log("in finnallly ")
        }
    };
}

export const createAdmin = (userId) => {
    return async (dispatch) => {
        try {
            let response = null            
            await axiosInstance.post("admin/create",userId).then(res => {
                console.log(res, "ress==>>>>")
                dispatch(uiActions.showNotification(
                    {
                        status: "success",
                        message: res.data.message
                    }
                ))})
            return response
        } catch (error) {
            console.log(error, "eroor")
            dispatch(uiActions.showNotification(
                {
                    status: "failure",
                    message: error.response.data.message
                }

            ))
        } finally {
            console.log("in finnallly ")
        }
    };
}