import React from 'react';
import "./AboutUs.css"

const AboutUs = () => {
  return (
    <div className="about-us">
      <h2>About Us</h2>
      <p>Welcome to SpacePe India Services LLP, where the future of digital transformation is being shaped today. At SpacePe, we're not just a company; we're a catalyst for change in the digital landscape, offering a suite of services that redefine the way businesses operate and interact. From state-of-the-art storage solutions to cutting-edge Banking as a Service (BaaS), Enterprise Resource Planning (ERP), Human Resources Management Systems (HRMS), Customer Relationship Management (CRM), and bespoke software customization services, we empower organizations to unlock their full potential.</p>

      <h2>Our Journey</h2>
      <p>Founded by a team of visionaries passionate about leveraging technology to solve real-world problems, SpacePe India Services LLP has grown from a startup with a bold idea to a leading technology services provider. Our journey has been driven by our unwavering commitment to innovation, excellence, and delivering tangible value to our clients.</p>

      <h2>Our Services</h2>
      <p>At SpacePe, our services portfolio is designed to meet the diverse needs of modern businesses:</p>
      <ul>
        <li><strong>Storage Services:</strong> Secure, scalable, and efficient storage solutions that ensure your data is accessible, safe, and optimized for performance.</li>
        <li><strong>Banking as a Service (BaaS):</strong> Revolutionizing the financial sector by providing agile, digital-first banking solutions that enable businesses to offer bespoke financial services.</li>
        <li><strong>Enterprise Resource Planning (ERP):</strong> Streamline your operations, enhance efficiency, and gain actionable insights with our comprehensive ERP solutions tailored to your business needs.</li>
        <li><strong>Human Resources Management Systems (HRMS):</strong> Transform your HR operations with our integrated HRMS solutions, designed to automate processes, enhance employee engagement, and optimize talent management.</li>
        <li><strong>Customer Relationship Management (CRM):</strong> Forge deeper connections with your customers with our CRM solutions, offering personalized experiences, improved customer insights, and enhanced loyalty.</li>
        <li><strong>Software Customization Services:</strong> Beyond our core offerings, we specialize in custom software development, tailoring solutions that fit your unique requirements and drive your business forward.</li>
      </ul>

      <h2>Our Philosophy</h2>
      <p><strong>Innovation, Integrity, and Impact</strong> are the core principles that guide everything we do at SpacePe. We believe in pushing the boundaries of what's possible, maintaining the highest standards of ethical business practices, and making a positive difference in the lives of our customers and communities.</p>

      <h2>Our Commitment</h2>
      <p>To our clients, we promise a partnership that goes beyond the traditional vendor-client relationship. We're committed to understanding your challenges, collaborating closely with your teams, and delivering solutions that not only meet but exceed your expectations.</p>

      <h2>Join Us on Our Journey</h2>
      <p>As we continue to grow and evolve, we invite you to join us on this exciting journey. Whether you're looking for a trusted partner to help navigate the complexities of digital transformation or interested in exploring the cutting edge of technology services, SpacePe India Services LLP is here to guide you every step of the way.</p>

      <p>Discover how we can help you achieve your digital ambitions. Welcome to SpacePe India Services LLP – where your future success begins today.</p>
    </div>
  );
}

export default AboutUs;
